import styled from "styled-components";
import { ArticleWithImage } from "../components/index-page/ArticleWithImage";
import { Socials } from "../components/common/Socials";

import homeTicker from "../../public/images/ani_house_slideshow.gif";
import blueprintHouse from "../../public/images/img_blueprint_house.svg";

import { MainContainer } from "../components/index-page/MainContainer";
import { MainHeader } from "../components/index-page/MainHeader";
import { FAQ } from "../components/index-page/FAQ";
import { PageScaffold } from "../components";
import { Menu } from "../components/common";
import {
  Box,
  createTheme,
  ThemeProvider,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "../components/material/Button";

const IndexPageContainer = styled.main`
  background-color: #cfe27f;
`;

const IndexPageHeaderBackground = styled.div`
  background-color: #c3b3e2;
`;

const IndexPageHeaderContainer = styled.div`
  height: 135px;
  margin: 0 auto;
  max-width: 1250px;
  padding: 3rem;
`;

function CharacterImage({ alt, src }: { alt: string; src: string }) {
  const theme = useTheme();

  return (
    <Grid item xs={10} sm={5} md={3}>
      <img
        style={{
          border: `3px solid ${theme.palette.primary.dark}`,
          borderRadius: "35px",
        }}
        alt={alt}
        height="100%"
        width="230px"
        src={src}
      />
    </Grid>
  );
}

const globalTheme = createTheme({
  palette: {
    text: {
      primary: "#001354",
    },
    primary: {
      light: "#c2b3e2",
      main: "#8987c6",
      dark: "#001354",
    },
  },
  spacing: [0, 4, 8, 16, 32, 48, 64, 80, 96, 112, 128],
  typography: {
    fontFamily: "abodeFont",
    h1: {
      fontFamily: "juicyFont",
    },
    h2: {
      fontSize: "2.5rem",
      lineHeight: "3.5rem",
      fontFamily: "juicyFont",
    },
    h3: {
      fontFamily: "juicyFont",
    },
    h4: {
      fontFamily: "abodeFontBold",
    },
    h5: {
      fontSize: "1.25rem",
      fontFamily: "abodeFontBold",
    },
    h6: {
      fontSize: "1.25rem",
      fontFamily: "abodeFont",
    },
    body1: {
      lineHeight: "1.75rem",
    },
  },
});

export default function Home() {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ThemeProvider theme={globalTheme}>
      <PageScaffold>
        <IndexPageContainer>
          <IndexPageHeaderBackground>
            <IndexPageHeaderContainer>
              <Menu />
            </IndexPageHeaderContainer>
          </IndexPageHeaderBackground>
          <MainHeader />
          <MainContainer>
            <div className="mt5" />
            <ArticleWithImage
              reverse={false}
              imageSrc={homeTicker}
              imageClassName="house-ticker"
              header={
                <>
                  From open skies
                  <br />
                  to ocean floors
                </>
              }
              contents="A Solstead NFT comes with a unique street address and a beautiful
            depiction of a one-of-a-kind home. We have a
            directory of all Solsteads, with a shareable page for each one to
            show off your home and the NFTs that live in the same wallet."
            />
            <Box marginBottom={"3rem"}>
              <Typography
                color="textPrimary"
                variant="h2"
                style={{ marginBottom: `${mdUp ? "0" : "1rem"}` }}
              >
                The Solsteads world is expanding.
              </Typography>
              <Typography color="textPrimary">
                <Typography component={"span"} variant="h5">
                  Introducing the Citizens.
                </Typography>
                <Typography
                  component={"span"}
                  variant="h6"
                  style={{ marginLeft: ".5rem" }}
                >
                  A fresh take on characters, ready to stir up some trouble.
                </Typography>
              </Typography>
            </Box>
            <Box marginBottom={"2rem"}>
              <Grid
                container
                spacing={4}
                justifyContent={"center"}
                alignItems="center"
              >
                <CharacterImage
                  alt="Character 1"
                  src={"/images/character_1.svg"}
                />

                <CharacterImage
                  alt="Character 2"
                  src={"/images/character_2.svg"}
                />

                <CharacterImage
                  alt="Character 3"
                  src={"/images/character_3.svg"}
                />

                <CharacterImage
                  alt="Character Placeholder"
                  src={"/images/character_placeholder.svg"}
                />
              </Grid>
            </Box>
            <Box marginBottom={"8rem"}>
              <a
                href="https://gallery.solsteads.com/gallery/citizens"
                target="_blank"
              >
                <Button variant="outlined">Gallery</Button>
              </a>
            </Box>
            <FAQ image={blueprintHouse} />
            <footer className="pv4 ph3 ph5-ns tc">
              <Socials />
            </footer>
          </MainContainer>
        </IndexPageContainer>
      </PageScaffold>
    </ThemeProvider>
  );
}
