import Head from "next/head";
import React from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
export function PageScaffold({ children }: React.PropsWithChildren<{}>) {
  return (
    <>
      <Head>
        <title>Solsteads - Home For Your NFTs</title>
        <meta
          name="description"
          content="You’ve treated yourself to some NFTs. Now it’s time to treat them
          to a beautiful home. Each Solstead is one of 2,472 unique
          homes in the first ever digital real estate on the Solana
          blockchain."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {children}
    </>
  );
}
