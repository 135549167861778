import { styled } from "@mui/material/styles";
import MButton, { ButtonProps } from "@mui/material/Button";

export const Button = styled(MButton, {
  shouldForwardProp: () => true,
})<ButtonProps>(({ variant }) => ({
  ...(variant == "outlined" && {
    backgroundColor: "transparent",
    border: "2px solid #0f1954",
    borderRadius: "23px",
    color: "#0f1954",
    fontFamily: "abodeFontBold",
    fontSize: "1.2rem",
    minHeight: "46px",
    padding: "0 1.25rem",
    textTransform: "none",
    ":hover": {
      border: "2px solid #0f1954",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    },
  }),
}));
