import React from "react";
import Image from "next/image";
import cloudflareLoader from "../../imageLoader";

type ArticleWithImageProps = {
  imageSrc: StaticImageData;
  imageClassName?: string;
  reverse?: boolean;
  header: React.ReactElement;
  contents: string;
};

function ArticleWithImage({
  imageSrc,
  imageClassName,
  reverse = false,
  header,
  contents,
}: ArticleWithImageProps) {
  return (
    <div
      className={`flex flex-column justify-center items-center mb5 mb6-l ${
        reverse ? "flex-row-reverse-l" : "flex-row-l"
      } `}
    >
      <div className={`mb4 ${reverse ? "ml5-l" : "mr5-l"}`}>
        <Image
          loader={cloudflareLoader}
          className={imageClassName || ""}
          src={imageSrc}
          alt="An example of a home"
          height={500}
          width={500}
        />
      </div>
      <div className="flex flex-column justify-center mw6">
        <h2 className="f3 f2-l fw1 lh-copy juicy">{header}</h2>
        <p className="f4 lh-copy abode">{contents}</p>
      </div>
    </div>
  );
}

export { ArticleWithImage };
