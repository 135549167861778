import React from "react";

function MainHeader() {
  return (
    <>
      <h1 className="hidden-header">Solsteads</h1>
      <div className="mb4 solsteads-header"></div>
    </>
  );
}

export { MainHeader };
