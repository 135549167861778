import { useState } from "react";
import { Button } from "../material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../hooks";
import { shortenPublicKey } from "../../utils";
import styled from "styled-components";

const StyledMenuItem = styled(MenuItem)`
  font-family: abodeFont !important;
  font-size: 1.25rem !important;
  color: ${(props) => props.theme.color.blue} !important;
  width: 155px !important;
  padding-left: 1rem !important;
`;

export function AccountButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { walletPublicKey, logOut } = useAuth();

  return (
    <div>
      <Button
        variant="outlined"
        id="logout-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {shortenPublicKey(walletPublicKey)}
      </Button>
      <Menu
        id="logout-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "logout-button",
        }}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose();
            logOut();
          }}
        >
          Logout
        </StyledMenuItem>
      </Menu>
    </div>
  );
}
