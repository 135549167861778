import { styled } from "@mui/material/styles";
import MPaper, { PaperProps } from "@mui/material/Paper";

export const Paper = styled(MPaper, {
  shouldForwardProp: () => true,
})<PaperProps>(() => ({
  position: "relative",
  color: "#0f1954",
  border: "3px solid #0f1954",
  borderRadius: "0",
  backgroundColor: "#efe4e1",
}));
