import React from "react";

function MainContainer({ children }) {
  return (
    <div className="main-container center flex flex-column items-center ph5 ph-4l">
      {children}
    </div>
  );
}

export { MainContainer };
