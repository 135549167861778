import Image from "next/image";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

import { Socials } from "./Socials";
import logo from "../../../public/images/img_solsteads_logo.svg";
import { Button } from "../material/Button";
import { AccountButton } from "./AccountButton";
import { useAuth } from "../../hooks";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 960px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

const MenuLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Logo = styled.div`
  margin-right: 1.5rem;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
`;

const MenuLink = styled.a`
  border-bottom: 2px solid transparent;
  color: #0f1954;
  font-family: abodeFont;
  font-size: 0.875rem;
  margin: 1rem 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  :hover {
    border-bottom: 2px solid #0f1954;
  }
`;

const TopLeftMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

type MenuProps = {
  hideSignIn?: boolean;
};

export function Menu({ hideSignIn }: MenuProps) {
  const { authToken } = useAuth();

  return (
    <MenuContainer>
      <Logo>
        <Link href="/" passHref>
          <div>
            <Image src={logo} alt="Solsteads Logo" />
          </div>
        </Link>
      </Logo>
      <MenuLinks>
        <Link href="/directory" passHref>
          <MenuLink>Directory</MenuLink>
        </Link>
        <Link href="/my-solsteads" passHref>
          <MenuLink>My Solsteads</MenuLink>
        </Link>
        <Link href="/marketplaces" passHref>
          <MenuLink>Marketplaces</MenuLink>
        </Link>
        <Link href="https://stake.solsteads.com" passHref>
          <MenuLink>Stake</MenuLink>
        </Link>
        <Link href="https://gallery.solsteads.com" passHref>
          <MenuLink>Gallery</MenuLink>
        </Link>
        <Link href="https://gallery.solsteads.com/snapshot" passHref>
          <MenuLink>Snapshot</MenuLink>
        </Link>
        {/* TODO update for future events */}
        {/* <Link href="/holidays" passHref>
          <MenuLink>Holidays</MenuLink>
        </Link> */}
      </MenuLinks>
      <TopLeftMenu>
        {!hideSignIn &&
          (authToken ? (
            <AccountButton />
          ) : (
            <Link href="/sign-in" passHref>
              <div>
                <Button variant="outlined">Sign In</Button>
              </div>
            </Link>
          ))}
        <Socials hideOnSmallScreens />
      </TopLeftMenu>
    </MenuContainer>
  );
}
