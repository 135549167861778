import React from "react";

function FAQQuestionAndAnswer({ question, answer }) {
  return (
    <>
      <p
        className="f4 lh-copy abode-bold
 mb2"
      >
        {question}
      </p>
      <p className="f5 lh-copy abode mb4">{answer}</p>
    </>
  );
}

export { FAQQuestionAndAnswer };
