import { useMediaQuery } from "@react-hook/media-query";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { Paper } from "../components/material/Paper";
import { Typography } from "../components/material/Typography";

const DirectoryListHeaderContainer = styled.div`
  border-bottom: 3px solid #0f1954;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  width: 100%;
`;

const DirectoryBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const DirectorySection = styled.div`
  border-right: 3px solid #0f1954;
  flex-grow: 1;
  padding: 1rem;
  &:last-child {
    border-right: none;
  }
`;

const ListItem = styled.div`
  margin-bottom: 1rem;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const DisabledListItem = styled.div`
  margin-bottom: 1rem;
  opacity: 0.6;
`;

type DirectoryListProps = {
  header: string;
  listItems: {
    entry: string;
    href: string;
  }[];
};

export function DirectoryList({ header, listItems }: DirectoryListProps) {
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");
  const isVerySmallScreen = useMediaQuery("only screen and (max-width: 600px)");

  let numberOfColumns = 3;
  if (isVerySmallScreen || listItems.length < 10) {
    numberOfColumns = 1;
  } else if (isSmallScreen || listItems.length < 30) {
    numberOfColumns = 2;
  }

  const chunks = chunkify(listItems, numberOfColumns);

  return (
    <Paper elevation={7}>
      <DirectoryListHeaderContainer>
        <Typography variant="h3">{header}</Typography>
      </DirectoryListHeaderContainer>
      <DirectoryBody>
        {chunks.map((chunk, i) => (
          <DirectorySection key={i}>
            {chunk.map((listItem, j) =>
              listItem.href ? (
                <Link key={j} href={listItem.href} passHref>
                  <ListItem>{listItem.entry.toUpperCase()}</ListItem>
                </Link>
              ) : (
                <DisabledListItem key={j}>
                  {listItem.entry.toUpperCase()}
                </DisabledListItem>
              )
            )}
          </DirectorySection>
        ))}
      </DirectoryBody>
    </Paper>
  );
}

// sourced from: https://stackoverflow.com/a/8189268
function chunkify(a, n, balanced = true) {
  if (n < 2) return [a];

  const len = a.length;
  const out = [];
  let i = 0;
  let size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(a.slice(i, (i += size)));
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--);
      out.push(a.slice(i, (i += size)));
    }
  } else {
    n--;
    size = Math.floor(len / n);
    if (len % size === 0) size--;
    while (i < size * n) {
      out.push(a.slice(i, (i += size)));
    }
    out.push(a.slice(size * n));
  }

  return out;
}
