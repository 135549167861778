import { styled } from "@mui/material/styles";
import MTypography, { TypographyProps } from "@mui/material/Typography";

export const Typography = styled(MTypography, {
  shouldForwardProp: () => true,
})<TypographyProps>(({ variant }) => ({
  color: "#0f1954",
  fontFamily: "abodeFont",
  ...(["h1", "h2", "h3"].includes(variant) && {
    fontFamily: "juicyFont",
  }),
  ...(["h4", "h5", "h6"].includes(variant) && {
    fontFamily: "abodeFontBold",
  }),
  ...(variant == "h4" && {
    fontSize: "1.7rem",
  }),
}));
