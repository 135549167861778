import { useMediaQuery } from "@react-hook/media-query";
import React from "react";
import Image from "next/image";
import twitterLogo from "../../../public/images/icon_twitter.svg";
import discordLogo from "../../../public/images/icon_discord.svg";
import cloudflareLoader from "../../imageLoader";

type SocialsProps = {
  hideOnSmallScreens?: boolean;
};

export const Socials = ({ hideOnSmallScreens }: SocialsProps) => {
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");

  if (isSmallScreen && hideOnSmallScreens) {
    return null;
  }

  return (
    <div className="ml4">
      <a
        className="link dim gray dib mr4 pt1"
        style={{ width: "2.9rem" }}
        href="https://twitter.com/solsteadsnft"
        title="Twitter link"
      >
        <Image loader={cloudflareLoader} src={twitterLogo} alt="Twitter logo" />
      </a>
      <a
        className="link dim gray dib mr3 pt1"
        style={{ width: "2.9rem" }}
        href="https://discord.gg/gcfEuWC5wT"
        title="Discord invite"
      >
        <Image loader={cloudflareLoader} src={discordLogo} alt="Discord logo" />
      </a>
    </div>
  );
};
