import React from "react";
import Image from "next/image";
import Link from "next/link";
import { FAQQuestionAndAnswer } from "./FAQQuestionAndAnswer";
import cloudflareLoader from "../../imageLoader";

function FAQ({ image }) {
  return (
    <div className="flex flex-column mw6 mw-100-l">
      <div className="flex flex-column-reverse flex-row-l items-center justify-center">
        <div className="w-50-l mr4-l">
          <h2 className="f3 fw1 lh-title juicy">F.A.Q.</h2>
          <FAQQuestionAndAnswer
            question="When was the mint?"
            answer="September 29th, 2021 at 6PM CT."
          />
          <FAQQuestionAndAnswer
            question="What was the price of a Solstead?"
            answer="0.25 SOL"
          />
        </div>
        <div>
          <Image
            loader={cloudflareLoader}
            src={image}
            alt="House blueprint"
            height={500}
            width={500}
          />
        </div>
      </div>
      <div className="flex flex-column flex-row-l faq-negative-margin">
        <div className="mr4">
          <FAQQuestionAndAnswer
            question="How does the “address” work?"
            answer="The metadata on each Solstead includes a unique street
                address (a number and street name). This website has a
                directory of all Solsteads, with a shareable page for each one
                (e.g. solsteads.com/123-main-street) to show off your home and
                all the NFTs that live in the same wallet."
          />

          <FAQQuestionAndAnswer
            question="What exactly am I buying when I buy a Solstead NFT?"
            answer="A Solstead NFT comes with a unique street address and a
                beautiful depiction of a one-of-a-kind home. The NFT does not
                guarantee the right to access anything on solsteads.com or any
                services beyond the NFT metadata and artwork."
          />
          <FAQQuestionAndAnswer question="Have you worn wigs?" answer="No." />

          <FAQQuestionAndAnswer question="Do you wear wigs?" answer="No." />
          <FAQQuestionAndAnswer
            question="Will you wear wigs?"
            answer="Maybe!"
          />
        </div>
        <div>
          <FAQQuestionAndAnswer
            question="Which charities are being donated to?"
            answer={
              <>
                10% of net proceeds of the mint sale will go to{" "}
                <Link href="https://www.rtno.org/">
                  <a>Rebuilding Together: New Orleans</a>
                </Link>
              </>
            }
          />
          <FAQQuestionAndAnswer
            question="Which secondary markets are you on?"
            answer={
              <>
                <Link href="https://www.tensor.trade/trade/solsteads_surreal_estate">
                  <a>Tensor</a>
                </Link>
                {" and "}
                <Link href="https://magiceden.io/marketplace/solsteads_surreal_estate">
                  <a>Magic Eden</a>
                </Link>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export { FAQ };
